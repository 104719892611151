import React, { FC, useContext } from "react";
import { useI18next, useTranslation, Link } from "gatsby-plugin-react-i18next";

import { IFooterItem, FooterStyle } from "common/types";

import { FooterTheme } from "common/context";
import { H6 } from "@global/Texts";

const FooterItem: FC<IFooterItem> = ({ title, links }) => {
  const { t } = useTranslation();
  const theme = useContext(FooterTheme);
  const color =
    theme === FooterStyle.DARK ? "text-white-light" : "text-secondary";
  const titleColor = theme === FooterStyle.DARK ? "text-white" : "text-primary";
	const { language } = useI18next();

  return (
    <div className="mt-8 lg:mt-0">
      <H6 className={`mb-4 ${titleColor}`}>{t(title)}</H6>
      <ul className="list-none">
        {links.map((link) => (
          <li className={`list-none mb-4 ${color}`} key={link.title}>
            {link.isExternal ? (
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className={`${
                  link.isDisabled
                    ? `${color} pointer-events-none`
                    : "hover:text-gray-light"
                }`}
              >
                {t(link.title)}
              </a>
            ) : (
              <Link
								placeholder={''}
                to={link.url}
                className={`${
                  link.isDisabled
                    ? `${color} pointer-events-none`
                    : "hover:text-gray-light"
                }`}
								lang={language}
              >
                {t(link.title)}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterItem;
