import SEO from '@layout/SEO'
import React from 'react'

import { useTranslation, Link, useI18next } from 'gatsby-plugin-react-i18next';
import { HOME_URL, TOURNAMENTS_URL } from 'common/const';

import metaImgPath from 'images/meta-img.png';
import Header from '@layout/Header';
import Footer from '@layout/Footer';
import { FooterTheme } from 'common/context';
import { FooterStyle } from 'common/types';
import { graphql } from 'gatsby';


export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
    allS3Object {
      nodes {
        localFile {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allFile {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
	}
`;

const LandingPage = ({ data }) => {
	const { t } = useTranslation();
	const { originalPath, language, languages } = useI18next();

	return (
		<div className='bg-black'>
			<SEO
				title={t('page.title')}
				description={t('comingSoon.pageDescription')}
				lang='en'
				imgPath={metaImgPath}
				imgAlt=''
				meta={[]}
			/>
			<main className='overflow-x-hidden mx-auto max-w-[2560px]'>

				<Header />
				<div className='flex h-[95vh] w-full bg-green-light'>Coin Page</div>
				
				<FooterTheme.Provider value={FooterStyle.DARK}>
					<Footer links={[]} />
				</FooterTheme.Provider>
			</main>
		</div>
	)
}

export default LandingPage;
