import React, { FC, useContext } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { FooterStyle, IFooterLinks } from 'common/types';
import { FooterTheme } from 'common/context';

import { StaticImage } from 'gatsby-plugin-image';
import FooterItem from './FooterItem';
import { UHP } from '@global/Texts';
import { SocialBar } from '@global/SocialBar';

const Footer: FC<IFooterLinks> = ({ links }) => {
	const { t } = useTranslation();
	const theme = useContext(FooterTheme);

	const items = [
		{
			title: 'footer.sectionsTitle',
			links: links,
		},
		{
			title: 'footer.resources.title',
			links: [
				{
					title: 'footer.resources.contact',
					url: `mailto:${process.env.GATSBY_CONTACT_EMAIL}`,
					isExternal: true,
				},
			],
		},
	];

	return (
		<footer
			className={`pb-16 pt-8 lg:pt-20 ${
				theme === FooterStyle.DARK ? 'bg-black' : 'bg-transparent'
			}`}
		>
			<div className="xl:container xl:mx-auto px-8 xl:px-32">
				<div className="flex flex-col lg:flex-row mb-20">
					<div className="lg:w-1/4 lg:mr-16">
						<StaticImage
							width={152}
							src="https://mokens-landing-bucket.s3.amazonaws.com/images/logos/basic-mokens-logo.png"
							alt={t('footer.mokensLogo')}
              placeholder='none'
							quality={100}
						/>
						<UHP
							className={`mt-9 ${
								theme === FooterStyle.DARK
									? 'text-white-light'
									: 'text-secondary'
							}`}
						>
							{t('footer.brandText')}
						</UHP>
					</div>
					<div className="flex flex-col lg:flex-row flex-1 justify-around mt-8 lg:mt-0">
						{items.map((item) => (
							<FooterItem
								key={item.title}
								title={item.title}
								links={item.links}
							/>
						))}
					</div>
				</div>
				<hr className="border-gray-lighter my-9 border-solid w-100" />

				<div className="grid grid-cols-12 grid-rows-1 gap-4">
					<a href={process.env.GATSBY_MONSTER_URL} target='_blank' className='row-start-2 col-start-1 col-span-2 lg:row-start-1 self-center'>
					<StaticImage
						src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/monster-league-studios-logo.png'
						alt={t('footer.monsterLogo')}
						width={75}
						placeholder='none'
						quality={100}
					/>
					</a>
					<p className='text-gray-light row-start-1 col-span-12 col-start-1 lg:row-start-1 lg:col-start-2 lg:col-span-6 self-center'>
						{t('footer.allRights')}
					</p>
					<div className='row-start-2 col-start-3 col-span-10 lg:row-start-1 lg:col-start-9 lg:col-span-4 self-center'>
						<SocialBar linkedSocialMedia={['telegram', 'discord', 'x', 'instagram', 'tiktok', 'youtube', 'twitch', 'medium', 'linkedin']} />
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
